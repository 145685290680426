.bar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    padding: 16px;
    border-top: 1px solid #e5e7eb; /* border-gray-200 */
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    z-index: 50;
  }
  
  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .text {
    font-size: 14px;
    color: #4b5563; /* text-gray-700 */
    margin-bottom: 16px;
  }
  
  .buttons {
    display: flex;
    justify-content: center;
    gap: 8px;
  }
  
  .button {
    padding: 8px 16px;
    border-radius: 4px;
    color: white;
    cursor: pointer;
  }
  
  .accept {
    background-color: #2563eb; /* bg-blue-600 */
    transition: background-color 0.3s ease;
  }
  
  .accept:hover {
    background-color: #1d4ed8; /* hover:bg-blue-700 */
  }
  
  .reject {
    background-color: #9ca3af; /* bg-gray-400 */
    transition: background-color 0.3s ease;
  }
  
  .reject:hover {
    background-color: #6b7280; /* hover:bg-gray-500 */
  }
  
  /* Responsive adjustments */
  @media (min-width: 768px) {
    .content {
      flex-direction: row;
      align-items: center;
    }
  
    .text {
      margin-right: 16px;
      margin-bottom: 0;
      flex: 1;
    }
  
    .buttons {
      justify-content: flex-end;
    }
  }
  